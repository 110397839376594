import axios from 'axios'
import { APIURL } from '../config/config'

export const getGroupPermission = (type, menuID) => {
  if (type !== undefined) {
    return axios.get(APIURL + '/grouppermission/menu/' + type + '/' + menuID)
  }
}

export const checkGroupPermission = (menuID, grouppermissions) => {

  if(grouppermissions.length === 0) {
    return false
  }

  const permission = grouppermissions.filter(val => val.menu_id === menuID)

  // if (menuID === 129) {
  //   console.log(permission)
  // }

  if (permission.length > 0) {
    return permission[0]
  } else {
    return false
  }
}

export const checkFormControls = (field_name, form_controls) => {
  if(form_controls.length === 0) {
    return false
  }

  const visibility = form_controls.filter(form_control => form_control.field_name === field_name )[0].visibility
  // console.log(field_name, 'visibility=', visibility, typeof visibility)

  if (visibility===1) {
    return true
  }
  return false
}

export const getFormControlName = (field_name, form_controls) => {
  if(form_controls.length === 0) {
    return false
  }

  const filter_form_control = form_controls.filter(form_control => form_control.field_name === field_name )
  // console.log(field_name, 'visibility=', visibility, typeof visibility)
  let form_control = filter_form_control[0]
  let visibility = form_control.visibility
  if (visibility===1) {
    return form_control.label_name
  }
  return '-'
}